<template>
  <transition name="fade" appear>
    <div id="practiceExercise">
      <section class="headNavInfo">
        <div class="headNavInfo_title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
            <el-breadcrumb-item>考试</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </section>
      <section class="box">
        <div class="menu">
          <template v-for="(item, index) in menuList">
            <div class="memu_item" :class="[index == nowIndex ? 'menu_itemActive' : '']" :key="index"
              @click="chooseMenu(index, item.courseCode)">
              {{ item.courseName }}
            </div>
          </template>
        </div>
        <div class="content">
          <template v-if="exerciseList.length>0">
            <div class="content_item" v-for="(item, index) in exerciseList" :key="index"
              @click="jump(item.practiceCode)">
              <div class="item_title">{{ item.courseName }}</div>
              <div class="item_btn">立即考试</div>
            </div>
          </template>
          <NoneImg v-else />
          <div class="pageInfo">
            <el-pagination background @current-change="handleCurrentChange" :current-page.sync="queryInfo.currPage"
              :page-size="queryInfo.pageSize" layout=" prev, pager, next,total, jumper" :total="count">
            </el-pagination>
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      menuList: [],
      //   左侧选中菜单
      nowIndex: "0",
      //   右侧列表
      exerciseList: [],
      // 分页参数
      queryInfo: {
        currPage: 1,
        pageSize: 10,
      },
      count: 0,
      currCouseCode: ''
    };
  },
  methods: {
    // 回到首页
    goHome () {
      this.$router.replace({
        path: "/home",
      });
    },
    // 返回上一页
    goBack () {
      this.$router.replace({
        path: "/practice",
      });
    },
    // 选择左侧菜单
    chooseMenu (index, courseCode) {
      this.nowIndex = index;
      this._getPracticeList(courseCode);
      this.currCouseCode = courseCode
    },
    // 修改当前页
    handleCurrentChange (newCurr) {
      this.queryInfo.currPage = newCurr;
      this._getPracticeList(this.currCouseCode);
    },
    //考试页面
    jump (practiceCode) {
      this.$router.push({
        path: "/practice/examination/exam",
        query: {
          practiceCode,
        },
      });
    },

    _getExamList () {
      //获取考试列表
      this.$api.exam.getExamList().then((res) => {
        if (res.data.code === 200) {
          this.menuList = res.data.data;
          if (this.menuList.length > 0) {
            this.chooseMenu(0, this.menuList[0].courseCode)
          }
        }
      });
    },

    _getPracticeList (courseCode) {
      //获取考试详情
      this.$api.exam
        .getPracticeList({
          courseCode,
          currPage: this.queryInfo.currPage,
          pageSize: this.queryInfo.pageSize,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.exerciseList = res.data.data.data;
            this.count = res.data.data.count;
          }
        });
    },
  },

  created () {
    this._getExamList();
  },
};
</script>

<style lang="less" scoped>
.box {
  position: relative;
  display: flex;
  width: 100%;
  height: 600px;
  background: white;
  .menu {
    width: 200px;
    background: #ececec;
    overflow-x: hidden;
    .memu_item {
      width: 100%;
      height: 40px;
      font-size: 18px;
      color: #0f100f;
      text-align: center;
      line-height: 40px;
      transition: all 0.2s linear;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .memu_item:hover {
      color: white;
      background: #dd3938;
      cursor: pointer;
    }
    .menu_itemActive {
      color: white;
      background: #dd3938;
      cursor: pointer;
    }
  }
  .content {
    width: 100%;
    padding: 13px 17px 39px;
    background: #f9fbfc;
    .content_item {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between;
      .item_title {
        padding-left: 22px;
        font-size: 18px;
        color: #101010;
      }
      .item_btn {
        height: 28px;
        width: 106px;
        color: white;
        font-size: 14px;
        background: #dd3938;
        border-radius: 2px;
        line-height: 28px;
      }
    }
  }
  .content_item:hover {
    cursor: pointer;
    .item_title {
      color: #dd3938;
    }
  }
  .content_item:active {
    transform: translate(1px, 2px);
  }
  .pageInfo {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: left;
  }
}
</style>
